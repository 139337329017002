<template>
  <div class="mobile-container">
    <div class="page-bg img-cover">
      <img src="../../assets/images/login-bg.png" />
    </div>
    <div class="page-content">
      <div class="msg">
        {{ msg }}
      </div>
    </div>
  </div>
</template>

<script>

 
export default {
  name: "404",
  components: {
  },
  data() {
    return {
      msg: '404'
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.msg) {
      this.msg = this.$route.query.msg
    }
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.page-bg {
  width: var(--max-width);
  height: 12rem;
  position: fixed;
  top: 0;
}
.page-content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .msg {
    padding: .4rem;
    text-align: center;
    font-size: .42rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 1rem;
  }
}
</style>
